angular.module('LeasePilot').factory('AttachmentService', [
  '$rootScope',
  function($rootScope) {
    // Since we now allow to persist PDFs, we need to transform those into PNGs
    // Each page in it's own image
    var attachments = {
      sitePlan: [],
      premisesPlan: [],
      exclusives: [],
      exclusiveUses: [],
      signageCriteria: [],
      commonAreaWorkPlan: [],
      initialDeliveryWorkPlan: [],
      tenantImprovementPlan: [],
      expansionAreaPlan: [],
      signageRendering: [],
      layoutPlan: [],
      expansionPremises: [],
      leasePlan: [],
      landlordWork: [],
      remainingPremises: [],
      designSchedule: [],
      restrictedArea: [],
      sndaForm: [],
      relocationExhibit: [],
      pylonSign: [],
      coverPage: [],
      landlordLienSubordination: [],
      refusalAreaPlan: [],
      offeringPlan: [],
      temporarySpacePlan: [],
      hvacSpecs: [],
      rooftopEquipment: [],
      tenantCertificateLiabilityInsurance: [],
      generatorExhibit: [],
      superiorRightsHolders: [],
      patioPlan: [],
      optionalUpload: [],
    };

    var pdfToImage = function(pdf, pageNumber, prefix, callback) {
      return pdf.getPage(pageNumber).then(function(page) {
        var scale = 1.5;
        var viewport = page.getViewport({ scale: scale });

        $('body').append(
          "<canvas id='pdfCanvas-" + prefix + pageNumber + "'></canvas>",
        );
        var canvas = $('#pdfCanvas-' + prefix + pageNumber)[0];
        var context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        var task = page.render({ canvasContext: context, viewport: viewport });
        return task.promise.then(function() {
          callback(pageNumber, canvas.toDataURL("image/png"));
          $('#pdfCanvas-' + prefix + pageNumber).remove();
        });
      });
    };

    var getFile = function(file, prefix) {
      attachments[prefix] = [];
      if (!file) {
        return;
      }

      if (file.url && !file.url.includes('?')) {
        file.url = file.url + "?" + window._azure_shared_access_signature;
      }

      if (file.url && file.url.toLowerCase().includes('.pdf')) {
        pdfjsLib.GlobalWorkerOptions.workerSrc = "/lp-ext/pdf.worker.4.6.82.js";

        if (file.url.startsWith('/')) {
          file.url = window.location.origin + file.url;
        }

        return pdfjsLib.getDocument({ url: file.url }).promise.then(
          function(pdf) {
            var promises = [];

            for (var pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
              promises.push(
                pdfToImage(pdf, pageNumber, prefix, function(index, image) {
                  attachments[prefix].push({ index: index, image: image });
                  $rootScope.$digest();
                }),
              );
            }

            return {
              isAttachment: true,
              list: promises,
            };
          },
          function(error) {
            console.log(error);
          },
        );
      } else {
        attachments[prefix].push({ index: 1, image: file.url });
      }
    };

    return {
      attachments: attachments,
      getFile: getFile,
    };
  },
]);
